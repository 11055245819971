import { Grid, GridItem, GridRow } from 'components/Grid';

import { CorpAnchorNavigation } from 'components/CorpAnchorNavigation';

import ExtensionComponents from 'containers/ExtensionComponents';
import { getLocalizedPath } from 'lib/utils/getLocalizedPath';
import { mapFeaturedContent } from 'lib/utils/mapFeaturedContent';
import { mapGenericAuthorableGridCards } from 'lib/utils/mapGenericAuthorableGridCards';
import dynamic from 'next/dynamic';
import React from 'react';
import LazyLoad from 'react-lazyload';
import { DictionaryItem } from 'types/Dictionary';
import Rewrite from '../../types/Rewrite';
import { IGlobalMetaData } from '../../types/GlobalMetadata';
import { IMetaData } from '../../types/MetaData';
import { CorpGenericContainer } from '../../components/CorpGenericContainer';
import { getCopy } from '../../lib/utils/getCopy';

const DynamicCorpContactCardContainer = dynamic(
  () =>
    import('components/CorpContactCardContainer').then((mod: any) => mod.CorpContactCardContainer),
  { ssr: true },
) as any; // TODO: import types.

const DynamicCorpFormWrapper = dynamic(
  () => import('components/CorpFormWrapper').then((mod: any) => mod.CorpFormWrapper),
  { ssr: true },
) as any; // TODO: import types.

const DynamicEvalancheForm = dynamic(
  () => import('../../components/EvalancheForm').then((mod: any) => mod.EvalancheForm),
  { ssr: true },
) as any; // TODO: import types.

const DynamicExampleComponent = dynamic(
  () => import('../../components/ExampleComponent').then((mod: any) => mod.ExampleComponent),
  { ssr: true },
) as any; // TODO: import types.

const DynamicForm = dynamic(() => import('../../components/Form').then((mod: any) => mod.Form), {
  ssr: true,
}) as any; // TODO: integration of newsletter end point.

const DynamicGenericAccordion = dynamic(
  () => import('../../components/GenericAccordion').then((mod: any) => mod.GenericAccordion),
  { ssr: true },
) as any; // TODO: import types.

const DynamicSocialMedia = dynamic(() =>
  import('components/CorpSocialMedia').then((mod: any) => mod.CorpSocialMedia),
) as any;

const DynamicCorpCardCarousel = dynamic(() =>
  import('../../components/CorpCardCarousel').then((mod: any) => mod.CorpCardCarousel),
) as any;

const DynamicLink = dynamic(() => import('../../components/Link').then((mod: any) => mod.Link), {
  ssr: true,
}) as any;

const DynamicHero = dynamic(() => import('../../components/Hero').then((mod: any) => mod.Hero), {
  ssr: true,
}) as any; // TODO: import types.

const DynamicSliderCarousel = dynamic(
  () => import('../../components/Slider').then((mod: any) => mod.SliderCarousel),
  { ssr: true },
) as any; // TODO: import types.

const DynamicIntroText = dynamic(
  () => import('components/IntroText').then((mod: any) => mod.IntroText),
  { ssr: true },
) as any; // TODO: import types.

const DynamicLongText = dynamic(
  () => import('components/LongText').then((mod: any) => mod.LongText),
  { ssr: true },
) as any; // TODO: import types.

const DynamicCorpMediaDownloadPreview = dynamic(
  () =>
    import('components/CorpMediaDownloadPreview').then((mod: any) => mod.CorpMediaDownloadPreview),
  { ssr: true },
) as any; // TODO: import types.

const DynamicTabsContainer = dynamic(() =>
  import('../../containers/tabContent').then((mod: any) => mod.TabsContainer),
) as any;

const DynamicGenericDownload = dynamic(
  () => import('components/CorpGenericDownload').then((mod: any) => mod.CorpGenericDownload),
  { ssr: true },
) as any; // TODO: import types.

const Iframe = dynamic(
  () => import('../../components/IFrameComponent').then((mod: any) => mod.Iframe),
  { ssr: true },
) as any;

const GenericSocialShare = dynamic(
  () => import('../../components/GenericSocialShare').then((mod: any) => mod.GenericSocialShare),
  { ssr: true },
) as any;

const DynamicAudio = dynamic(() => import('components/Audio').then((mod: any) => mod.Audio), {
  ssr: true,
}) as any; // TODO: import types.

const DynamicAuthrableContentCard = dynamic(
  () =>
    import('../../components/AuthorableContentCard').then((mod: any) => mod.AuthorableContentCard),
  { ssr: true },
) as any; // TODO: import types.

const DynamicBanner1 = dynamic(() => import('components/Banner1').then((mod: any) => mod.Banner1), {
  ssr: true,
}) as any; // TODO: import types.
const DynamicBanner2 = dynamic(() => import('components/Banner2').then((mod: any) => mod.Banner2), {
  ssr: true,
}) as any; // TODO: import types.
const DynamicCorpContentSlider = dynamic(
  () => import('../../components/CorpContentSlider').then((mod: any) => mod.CorpContentSlider),
  { ssr: true },
) as any; // TODO: import types.

const DynamicFeaturedContent = dynamic(
  () => import('../../components/FeaturedContent').then((mod: any) => mod.FeaturedContent),
  { ssr: true },
) as any; // TODO: import types.

const DynamicGenericGridAuthorable = dynamic(
  () =>
    import('../../components/GenericGridAuthorable').then((mod: any) => mod.GenericGridAuthorable),
  { ssr: true },
) as any;

const DynamicCorpHero = dynamic(
  () => import('components/CorpHero').then((mod: any) => mod.CorpHero),
  { ssr: true },
) as any; // TODO: import types.

const DynamicCorpVideoHero = dynamic(
  () => import('components/CorpVideoHero').then((mod: any) => mod.CorpVideoHero),
  { ssr: true },
) as any; // TODO: import types.

const DynamicCorpVideoHeroCarousel = dynamic(
  () => import('components/CorpVideoHeroCarousel').then((mod: any) => mod.CorpVideoHeroCarousel),
  { ssr: true },
) as any; // TODO: import types.

const DynamicCorpOurPeople = dynamic(
  () => import('components/CorpOurPeople').then((mod: any) => mod.CorpOurPeople),
  { ssr: true },
) as any; // TODO: import types.

const DynamicBigNumbers = dynamic(
  () => import('components/BigNumbers').then((mod: any) => mod.BigNumbers),
  { ssr: true },
) as any;

const DynamicCorpGenericAccordion = dynamic(
  () => import('components/CorpGenericAccordion').then((mod: any) => mod.CorpGenericAccordion),
  { ssr: true },
) as any; // TODO: import types.

const DynamicCorpTimelineAccordionWrapper = dynamic(
  () =>
    import('components/CorpTimelineAccordionWrapper').then(
      (mod: any) => mod.CorpTimelineAccordionWrapper,
    ),
  { ssr: true },
) as any; // TODO: import types.

const DynamicLocationComponent = dynamic(
  () => import('../../components/LocationComponent').then((mod: any) => mod.LocationComponent),
  { ssr: true },
) as any; // TODO: import types.

const DynamicShortHero = dynamic(
  () => import('components/ShortHero').then((mod: any) => mod.ShortHero),
  { ssr: true },
) as any; // TODO: import types.

const QuickLink = dynamic(() => import('components/QuickLink').then((mod: any) => mod.QuickLink), {
  ssr: true,
}) as any; // TODO: import types.

const DynamicGenericLine = dynamic(
  () => import('../../components/GenericLine').then((mod: any) => mod.GenericLine),
  { ssr: true },
) as any; // TODO: import types.

const DynamicCorpGridColumnComponent = dynamic(
  () =>
    import('../../components/CorpGridColumnComponent').then(
      (mod: any) => mod.CorpGridColumnComponent,
    ),
  { ssr: true },
) as any; // TODO: import types.

const DynamicImageCopy = dynamic(
  () => import('../../components/ImageCopy').then((mod: any) => mod.ImageCopy),
  { ssr: true },
) as any; // TODO: import types.

const DynamicSpacer = dynamic(
  () => import('../../components/Spacer').then((mod: any) => mod.Spacer),
  { ssr: true },
) as any;

const DynamicProgressBar = dynamic(
  () => import('components/ProgressBar/ProgressBar').then((mod: any) => mod.ProgressBar),
  { ssr: true },
) as any;

const DynamicCorpGenericContainer = dynamic(
  () => import('components/CorpGenericContainer').then((mod: any) => mod.CorpGenericContainer),
  { ssr: true },
) as any;

export function getComponentOutput(component: any, element: any) {
  return (
    <div
      id={component.anchorNav?.navigationTitle?.split(' ')?.join('-')}
      style={{ scrollMarginTop: '150px' }}
      data-component={component?.__typename}
    >
      {element}
    </div>
  );
}

// PLEASE KEEP THIS MAPPER IN ALPHABETICAL ORDER, EASIER TO FIND STUFF WHEN TROUBLESHOOTING
const componentsMap = {
  AnchorNavigationList: 'AnchorNavigationList',
  AudioComponent: 'AudioComponent',
  AuthorableContentCard: 'AuthorableContentCard',
  CorpBanner1: 'CorpBanner1',
  Banner2: 'Banner2',
  BigNumber: 'BigNumber',
  CarouselComponent: 'CarouselComponent',
  CorpCardCarousel: 'CorpCardCarousel',
  CorpContactCardContainer: 'CorpContactCardContainer',
  CorpGenericAccordion: 'CorpGenericAccordion',
  CorpGenericContainer: 'CorpGenericContainer',
  CorpHero: 'CorpHero',
  CorpForm: 'CorpForm',
  CorpVideoHero: 'CorpVideoHero',
  CorpVideoHeroCarousel: 'CorpVideoHeroCarousel',
  CorpOurPeople: 'CorpOurPeople',
  CorpTimelineAccordionWrapper: 'CorpTimelineAccordionWrapper',
  CorpMediaDownloadPreview: 'CorpMediaDownloadPreview',
  DynamicCardGrid: 'ExtensionComponents',
  EvalancheFormComponent: 'EvalancheFormComponent',
  ExampleComponent: 'ExampleComponent',
  FeaturedContent: 'FeaturedContent',
  Form: 'Form',
  GenericAccordion: 'GenericAccordion',
  GenericAccordionTopRated: 'ExtensionComponents',
  // TODO correct spelling of GenericCardCarouseComponent to GenericCardCarouselComponent
  GenericCardCarouseComponent: 'ExtensionComponents',
  GenericDownloadComponent: 'GenericDownloadComponent',
  GenericFollowUs: 'GenericFollowUs',
  GenericGridAuthorable: 'GenericGridAuthorable',
  GenericLine: 'GenericLine',
  GenericSocialShare: 'GenericSocialShare',
  CorpGridColumnComponent: 'CorpGridColumnComponent',
  HeroCarouselComponent: 'HeroCarouselComponent',
  HeroComponent: 'Hero',
  Iframe: 'Iframe',
  ImageCopy: 'ImageCopy',
  IntroText: 'IntroText',
  Link: 'Link',
  LocationComponent: 'LocationComponent',
  LongText: 'LongText',
  ProductBanner: 'ProductBanner',
  ProgressBar: 'ProgressBar',
  QuickLink: 'QuickLink',
  ShortHero: 'ShortHero',
  Spacer: 'Spacer',
  Tab: 'Tab',
};

// Need to pull out rewrites, until then this is to force a default value to enable build.
const rewritesDefault: Record<string, any> = [];

// Component mapper for generic pages.
// PLEASE KEEP THIS MAPPER IN ALPHABETICAL ORDER, EASIER TO FIND STUFF WHEN TROUBLESHOOTING
const PageComponentMapper = ({
  components,
  copy,
  rewrites = rewritesDefault,
  apiUrl,
  metadata,
  globalMetadata,
  useLazyLoadWrapper,
}: {
  components: any;
  copy: DictionaryItem[];
  rewrites?: Record<string, Rewrite>;
  apiUrl?: string;
  globalMetadata?: IGlobalMetaData;
  metadata?: IMetaData;
  useLazyLoadWrapper?: boolean;
}): JSX.Element => {
  if (components && !Array.isArray(components)) {
    throw new Error(`components is not an array ${components}`);
  }
  return (
    <>
      {components &&
        components.length > 0 &&
        components?.map((component: any, index: number) => {
          const componentName = (componentsMap as any)[component?.__typename];
          if (componentName === undefined)
            console.error('Component not mapped', component?.__typename);
          const componentOutput = (() => {
            switch (componentName) {
              case 'AudioComponent':
                return getComponentOutput(
                  component,
                  <DynamicAudio
                    audio={component.audioFile?.url}
                    asset={{
                      id: component.image?.[0]?.id,
                      url: component.image?.[0]?.asset?.[0]?.image,
                    }}
                    title={component.title}
                    description={component.description}
                    transcript={component.transcript}
                    copy={copy}
                  />,
                );

              case 'AuthorableContentCard':
                return getComponentOutput(
                  component,
                  <Grid>
                    <GridRow columns={16}>
                      <GridItem colSpan={8}>
                        <DynamicAuthrableContentCard
                          title={component.name}
                          subtitle={component.subtitle}
                          copy={component.copy}
                          videoId={component.videoId}
                          image={component.image?.asset?.[0]?.url}
                          link={
                            component.link && {
                              url: component.link.id,
                              text: component.link.displayName,
                            }
                          }
                          height="100%"
                        />
                      </GridItem>
                    </GridRow>
                  </Grid>,
                );
              case 'AnchorNavigationList':
                const anchorNavLinks = component?.anchorNavigations?.map((nav: any) => ({
                  id: nav.id,
                  url: nav.navigationTitle.split(' ').join('-'),
                  displayName: nav.navigationTitle,
                }));
                return <CorpAnchorNavigation links={anchorNavLinks} backgroundColor="#FDFBF9" />;
              case 'CorpBanner1':
                return getComponentOutput(
                  component,
                  <DynamicBanner1
                    color={component.color}
                    superText={component.superText}
                    title={component.title}
                    description={component.description}
                    link={{
                      url: component.link?.url,
                      text: component.link?.displayName || '',
                      textColor: component.ctaTheme?.text.hex || '#fff',
                      backgroundColor: component.ctaTheme?.background.hex || '#000',
                    }}
                  />,
                );
              case 'Banner2':
                return getComponentOutput(
                  component,
                  <DynamicBanner2
                    superText={component.superText}
                    title={component.title}
                    description={component.description}
                    backgroundAsset={component.backgroundAsset?.asset[0]}
                    mobileBackgroundAsset={component.mobileBackgroundAsset?.asset[0]}
                    imageAlignment={component.imageAlignment}
                    fullBleed={component.fullBleed}
                    link={component.link}
                  />,
                );
              case 'CarouselComponent':
                const {
                  carouselSlides,
                  bottomLeftAsset,
                  topRightAsset,
                  heading,
                  title,
                  slidesToShow,
                  backgroundImg,
                  cardVariant
                } = component;
                return getComponentOutput(
                  component,
                  <DynamicCorpContentSlider
                    heading={heading}
                    title={title}
                    backgroundImg={backgroundImg}
                    slidesToShow={slidesToShow}
                    slides={carouselSlides}
                    bottomLeftAsset={bottomLeftAsset}
                    topRightAsset={topRightAsset}
                    cardVariant={cardVariant}
                  />,
                );
              case 'CorpForm':
                return getComponentOutput(
                  component,
                  <DynamicCorpFormWrapper id={component.id} data={component} copy={copy} />,
                );
              case 'CorpContactCardContainer':
                return getComponentOutput(
                  component,
                  <DynamicCorpContactCardContainer id={component.id} data={component} />,
                );
              case 'CorpTimelineAccordionWrapper':
                return getComponentOutput(
                  component,
                  <DynamicCorpTimelineAccordionWrapper id={component.id} data={component} />,
                );
              case 'EvalancheFormComponent':
                return getComponentOutput(
                  component,
                  <DynamicEvalancheForm
                    host={component.evalancheHost}
                    formId={component.formId}
                    collapsed={component.collapsed}
                    accordionTitle={component.accordionTitle}
                    fieldsBeforeAccordion={component.fieldsBeforeAccordion}
                    fieldsAfterAccordion={component.fieldsAfterAccordion}
                  />,
                );
              case 'ExampleComponent':
                return getComponentOutput(
                  component,
                  <DynamicExampleComponent
                    textalignment={component.textAlignment}
                    assetsComponents={component.assetsComponents}
                    title={component.title}
                    description={component.description}
                  />,
                );
              case 'ExtensionComponents':
                return (
                  <ExtensionComponents
                    data={component}
                    containerPosition={index + 1}
                    rewrites={rewrites}
                  />
                );
              case 'FeaturedContent':
                return getComponentOutput(
                  component,
                  <DynamicFeaturedContent
                    id={`feat-cont-${component.id}`}
                    title={component.title}
                    superText={component?.superText || ''}
                    contentItems={mapFeaturedContent(component?.items || [], component.dictionary)}
                  />,
                );
              case 'GenericGridAuthorable':
                return getComponentOutput(
                  component,
                  <DynamicGenericGridAuthorable
                    id={`generic-authorable-grid-${component?.id}`}
                    key={component?.id}
                    heading={component?.title}
                    isRegularGrid={component?.regularLayout}
                    subHeading={component?.superText}
                    cards={mapGenericAuthorableGridCards(
                      component?.cards || [],
                      component.dictionary,
                      rewrites,
                    )}
                  />,
                );
              case 'Form':
                return getComponentOutput(
                  component,
                  <DynamicForm formData={component} requestURL={`${apiUrl}/email`} />,
                );
              case 'GenericAccordion':
                return getComponentOutput(
                  component,
                  <DynamicGenericAccordion
                    data={component.genericAccordionItems}
                    $isNumbered={component.numbered}
                  />,
                );
              case 'CorpHero':
                return getComponentOutput(component, <DynamicCorpHero {...component} />);
              case 'CorpVideoHero':
                return getComponentOutput(
                  component,
                  <DynamicCorpVideoHero copy={copy} {...component} />,
                );
              case 'CorpVideoHeroCarousel':
                return getComponentOutput(
                  component,
                  <DynamicCorpVideoHeroCarousel copy={copy} {...component} />,
                );
              case 'CorpOurPeople':
                return getComponentOutput(
                  component,
                  <DynamicCorpOurPeople copy={copy} {...component} />,
                );
              case 'CorpGenericAccordion':
                return getComponentOutput(
                  component,
                  <DynamicCorpGenericAccordion
                    data={component.corpGenericAccordionItems}
                    getItemBody={(bodyItems: any) => (
                      <PageComponentMapper
                        components={bodyItems}
                        copy={copy}
                        rewrites={rewrites}
                        apiUrl={apiUrl}
                        useLazyLoadWrapper={false}
                      />
                    )}
                    $isNumbered={component.numbered}
                  />,
                );
              case 'ShortHero':
                return getComponentOutput(component, <DynamicShortHero data={component} />);
              case 'QuickLink':
                return getComponentOutput(component, <QuickLink data={component} />);
              case 'GenericFollowUs':
                return <DynamicSocialMedia data={component} />;
              case 'CorpCardCarousel':
                return (
                  <DynamicCorpCardCarousel
                    settings={{ infinite: false }}
                    copyDictionary={copy}
                    carouselData={component}
                    cardData={component.cardData}
                  />
                );
              case 'GenericLine':
                return getComponentOutput(component, <DynamicGenericLine enable={component} />);
              case 'Link':
                let LinkUrl;
                if (component.internalLink && component.internalLink.__typename === 'GenericPage') {
                  LinkUrl = `/${component.internalLink.slug}`;
                } else if (
                  component.internalLink &&
                  component.internalLink.__typename === 'ProductSubcategoryPage'
                ) {
                  LinkUrl = getLocalizedPath(
                    rewrites.productsSubCategory,
                    component.internalLink.slug,
                  );
                } else if (
                  component.internalLink &&
                  component.internalLink.__typename === 'ProductCategoryPage'
                ) {
                  LinkUrl = getLocalizedPath(
                    rewrites.productsCategory,
                    component.internalLink.slug,
                  );
                } else if (
                  component.internalLink &&
                  (component.internalLink.__typename === 'RecipeLandingPage' ||
                    component.internalLink.__typename === 'ProductLandingPage')
                ) {
                  LinkUrl = `/${component.internalLink.slug}`;
                } else if (
                  component.internalLink &&
                  component.internalLink.__typename === 'RecipeCategory'
                ) {
                  LinkUrl = getLocalizedPath(rewrites.recipesCategory, component.internalLink.slug);
                } else if (
                  component.internalLink &&
                  component.internalLink.__typename === 'RecipeSubcategory'
                ) {
                  LinkUrl = getLocalizedPath(
                    rewrites.recipesSubCategory,
                    component.internalLink.slug,
                  );
                } else {
                  LinkUrl = component.url;
                }
                return getComponentOutput(
                  component,
                  <DynamicLink
                    href={LinkUrl}
                    target={component.openInNewTab ? '_blank' : '_self'}
                    // TODO - Remove children from Link component
                    // eslint-disable-next-line react/no-children-prop
                    children={component.displayName}
                  />,
                );
              case 'CorpGridColumnComponent':
                return getComponentOutput(
                  component,
                  <DynamicCorpGridColumnComponent data={component} />,
                );
              case 'Hero':
                return getComponentOutput(
                  component,
                  <DynamicHero
                    title={component.title}
                    description={component.description}
                    mobileImage={component.mobileImage || {}}
                    image={component.imageComponent?.asset?.[0]}
                    superText={component.superText}
                    alignment={component.alignment}
                    verticalAlignment={component.verticalAlignment}
                    verticalAlignmentMobile={component.verticalAlignmentMobile}
                    topRightAsset={component.topRightImageAsset}
                    bottomLeftAsset={component.bottomLeftImageAsset}
                    getLocalizedPath={getLocalizedPath}
                  />,
                );
              case 'HeroCarouselComponent':
                return getComponentOutput(
                  component,
                  <DynamicSliderCarousel
                    data={component.banners}
                    isForBanners
                    type="carousel"
                    rewrites={rewrites}
                    getLocalizedPath={getLocalizedPath}
                  />,
                );
              case 'ImageCopy':
                return getComponentOutput(
                  component,
                  <DynamicImageCopy
                    imageCopyItems={component.imageCopyItem}
                    topRightAsset={component.topRightAsset?.asset[0]}
                    bottomLeftAsset={component.bottomLeftAsset?.asset[0]}
                  />,
                );
              case 'IntroText':
                return getComponentOutput(
                  component,
                  <DynamicIntroText
                    superscript={component.super}
                    title={component.title}
                    description={component.description}
                    richText={component.richText}
                    link={component.link}
                  />,
                );
              case 'LongText':
                return getComponentOutput(
                  component,
                  <DynamicLongText theme={component.theme} longText={component.longText} />,
                );
              case 'CorpMediaDownloadPreview':
                return <DynamicCorpMediaDownloadPreview data={component} copy={copy} />;
              case 'LocationComponent':
                return getComponentOutput(
                  component,
                  <DynamicLocationComponent
                    data={component}
                    copy={copy}
                    getItemBody={(bodyItems: any) => {
                      return (
                        <PageComponentMapper
                          components={bodyItems}
                          copy={copy}
                          rewrites={rewrites}
                          apiUrl={apiUrl}
                          useLazyLoadWrapper={false}
                        />
                      );
                    }}
                    onFilterChange={() => null}
                    filterValue={'all'}
                  />,
                );
              case 'Tab':
                return getComponentOutput(
                  component,
                  <DynamicTabsContainer
                    tabs={component}
                    copy={copy}
                    rewrites={rewrites}
                    PageComponentMapper={PageComponentMapper}
                  />,
                );
              case 'GenericDownloadComponent':
                return getComponentOutput(
                  component,
                  <DynamicGenericDownload
                    superText={component.superText}
                    title={component.title}
                    body={component.body}
                    assetComponent={component.assetComponent}
                    downloadableFile={component.downloadableFile}
                    label={getCopy('download', copy)}
                  />,
                );
              case 'Iframe':
                return getComponentOutput(
                  component,
                  <Iframe
                    iframeUrl={component.iframeUrl}
                    iframeWidth={component.iframeWidth}
                    iframeHeight={component.iframeHeight}
                    iframeType={component.iframeType}
                    alignment={component.alignment}
                  />,
                );
              case 'GenericSocialShare':
                return getComponentOutput(
                  component,
                  <GenericSocialShare
                    shareImage={metadata?.images?.[0]?.url || globalMetadata?.image?.url || ''}
                    genericSocialShares={{
                      socialMediaList: component.socialMediaList,
                      buttonLabel: component.buttonLabel,
                    }}
                  />,
                );
              case 'Spacer':
                return getComponentOutput(
                  component,
                  <DynamicSpacer size={component.height} isCorporate />,
                );
              case 'CorpGenericContainer':
                return getComponentOutput(
                  component,
                  <CorpGenericContainer>
                    <PageComponentMapper
                      components={component.content}
                      copy={copy}
                      rewrites={rewrites}
                      apiUrl={apiUrl}
                      useLazyLoadWrapper={false}
                    />
                  </CorpGenericContainer>,
                );
              case 'ProgressBar':
                return getComponentOutput(
                  component,
                  <DynamicProgressBar
                    current={component.current}
                    end={component.end}
                    start={component.start}
                    color={component.color}
                    headline={component.headline}
                    caption={component.caption}
                  />,
                );
              case 'BigNumber':
                return getComponentOutput(
                  component,
                  <DynamicBigNumbers items={component.bigNumberItems} theme={component.theme} />,
                );
              default:
                return <div />;
            }
          })();
          // if (useLazyLoadWrapper) {
          //   // * Lazy loading while used on the generic output might not be able to tell the
          //   // * compoenent height to set a template for.
          //   // * if a certain heigh is needed other than the generic 200px then each componentOutput
          //   // * needs to be wrapped in <Lazyloading /> spareltly.
          //   return (
          //     <LazyLoad key={component?.id} once>
          //       <div id={component?.id}>
          //         {componentOutput}
          //       </div>
          //     </LazyLoad>
          //   );
          // }
          return (
            <div key={component?.id} id={component?.id}>
              {componentOutput}
            </div>
          );
        })}
    </>
  );
};

PageComponentMapper.defaultProps = {
  metadata: '',
  globalMetadata: '',
  useLazyLoadWrapper: true,
};
export default PageComponentMapper;
